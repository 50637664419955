import React from "react"
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'
import PropTypes from "prop-types"
import Header from "./header"
import CallButton from "./call-button"
const Layout = ({ children }) => {
  return (
    <>
      <CallButton />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header />
      <div>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
