import React from 'react'

const CallButton = () => {
    return (
        <div className='call-btn bg-red-600 text-gray-100 mx-4' style={{ width: 150 }}>
            <a href="tel:713-865-6014">Call Now</a>
        </div>
    );
};

export default CallButton;
