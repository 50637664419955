import React from "react"
import logo from '../images/rr-logo.svg';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const serviceAreas = [
  { ref: '/cities/the-woodlands', serviceArea: 'The Woodlands, Texas' },
  { ref: '/cities/kingwood', serviceArea: 'Kingwood, Texas' },
  { ref: '/cities/atascosita', serviceArea: 'Atascosita, Texas' },
  { ref: '/cities/humble', serviceArea: 'Humble, Texas' },
  { ref: '/cities/conroe', serviceArea: 'Conroe, Texas' },
  { ref: '/cities/baytown', serviceArea: 'Baytown, Texas' },
  { ref: '/cities/houston', serviceArea: 'Houston, Texas' },
  { ref: '/cities/cleveland', serviceArea: 'Cleveland, Texas' },
  { ref: '/cities/dayton', serviceArea: 'Dayton, Texas' },
  { ref: '/cities/crosby', serviceArea: 'Crosby, Texas' },
  { ref: '/cities/splendora', serviceArea: 'Splendora, Texas' },
  { ref: '/cities/huffman', serviceArea: 'Huffman, Texas' },
  { ref: '/cities/new-caney', serviceArea: 'New Caney, Texas' },
  { ref: '/cities/porter', serviceArea: 'Porter, Texas' },
  { ref: '/cities/liberty', serviceArea: 'Liberty, Texas' },
];

const Header = () => (
<div>
  <nav style={{ marginTop: -25 }} className="bg-red-600">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <a href="/">
              <img className="h-12" src={logo} alt="Rayburn's Roofing Logo" />
            </a>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline">
              <AnchorLink to='/#services' className='ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-white hover:bg-red-700 focus:outline-none focus:text-white focus:bg-red-700' title='Services' />
              <AnchorLink to='/#testimonials' className='ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-white hover:bg-red-700 focus:outline-none focus:text-white focus:bg-red-700' title='Testimonials' />
              <AnchorLink to='/#gallery' className='ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-white hover:bg-red-700 focus:outline-none focus:text-white focus:bg-red-700' title='Gallery' />
              <AnchorLink to='/#contact' className='ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-white hover:bg-red-700 focus:outline-none focus:text-white focus:bg-red-700' title='Contact Us' />
              <div className="relative">
          <p
            onMouseEnter={() => {
              document.getElementById('service-menu').classList.remove('hidden');
            }}
            onMouseLeave={() => {
              document.getElementById('service-menu').classList.add('hidden');
              
            }} 
            className="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-white hover:bg-red-700 focus:outline-none focus:text-white focus:bg-red-700"
          >
            Areas We Service
          </p>


          <div 
                      onMouseEnter={() => {
                        document.getElementById('service-menu').classList.remove('hidden');
                      }}
                      onMouseLeave={() => {
                        document.getElementById('service-menu').classList.add('hidden');
                      }} 
          id="service-menu" className="hidden z-40 absolute ml-56 transform -translate-x-1/2 px-2 w-screen max-w-md sm:px-0">
            <div className="rounded-lg shadow-lg">
              <div className="rounded-lg shadow-xs overflow-hidden bg-white">
                <div className="px-5 py-5 bg-gray-50 space-y-5 sm:px-8 sm:py-8">
                <div className="grid grid-cols-2 row-gap-4 col-gap-8">
                  {serviceAreas.map((x, i) => {
                    return (
                      <a key={i} href={x.ref} className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                        {x.serviceArea.split(',')[0]}
                      </a>
                    )
                  })}
                </div>
                  <div className="text-sm leading-5">
                    <a href="/service-areas" className="font-medium text-red-600 hover:text-red-500 transition ease-in-out duration-150">
                      View Service Area Map &rarr;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="ml-4 flex items-center md:ml-6">
            <button className="p-1 border-2 border-transparent text-red-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-700" aria-label="Notifications">
            <a href="mailto:rayburnroofing@gmail.com?subject=Website Inquiry" className="px-3 py-2 rounded-md text-sm font-medium text-white bg-red-900 focus:outline-none focus:text-white focus:bg-red-700">Get Quote
              </a>
            </button>
            <button className="p-1 border-2 border-transparent text-red-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-700" aria-label="Notifications">
            <a href="tel:713-865-6014" className="px-3 py-2 rounded-md text-sm font-medium text-white bg-red-900 focus:outline-none focus:text-white focus:bg-red-700">Call Now
              </a>
            </button>
          </div>
        </div>
        <div className="-mr-2 flex md:hidden">
          {/* Mobile menu button -*/}
          <button
            onClick={() => {
              const menu = document.getElementById('mobile-menu').classList;
              if (menu.contains('hidden')) {
                document.getElementById('mobile-menu').classList.remove('hidden');
                document.getElementById('hamburger-menu').classList.add('hidden');
                document.getElementById('hamburger-menu').classList.remove('block');
                document.getElementById('close-hamburger').classList.add('block');
                document.getElementById('close-hamburger').classList.remove('hidden');
              } else {
                document.getElementById('mobile-menu').classList.add('hidden');
                document.getElementById('hamburger-menu').classList.add('block');
                document.getElementById('hamburger-menu').classList.remove('hidden');
                document.getElementById('close-hamburger').classList.add('hidden');
                document.getElementById('close-hamburger').classList.remove('block');
              }
            }}
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white">
            {/* Menu open: "hidden", Menu closed: "block" */}
            <svg id='hamburger-menu' className="h-6 w-6 block" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            {/* Menu open: "block", Menu closed: "hidden" */}
            <svg id='close-hamburger' className="h-6 w-6 hidden" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div id="mobile-menu" className="hidden z-40 absolute top-8 inset-x-0 p-2 transition transform origin-top-right md:hidden">
    <div className="rounded-lg shadow-lg">
      <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5 space-y-6">
          <div>
            <nav className="grid row-gap-8" onClick={() => document.getElementById('mobile-menu').classList.add('hidden')}>
              <AnchorLink to='/#services' className='ml-4 px-3 py-2 rounded-md text-sm font-medium bg-red-700 text-white' title='Services' />
              <AnchorLink to='/#gallery' className='ml-4 px-3 py-2 rounded-md text-sm font-medium bg-red-700 text-white' title='Gallery' />
              <AnchorLink to='/#testimonials' className='ml-4 px-3 py-2 rounded-md text-sm font-medium bg-red-700 text-white' title='Testimonials' />
              <AnchorLink to='/#contact' className='ml-4 px-3 py-2 rounded-md text-sm font-medium bg-red-700 text-white' title='Contact Us' />
            </nav>
          </div>
        </div>
        <div className="py-6 px-5 space-y-6">
          <p className="text-base leading-6 text-red-600 font-semibold tracking-wide uppercase">Service Areas</p>
          <div className="grid grid-cols-2 row-gap-4 col-gap-8">
          {serviceAreas.map((x, i) => {
            return (
              <a key={i} href={x.ref} className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                {x.serviceArea.split(',')[0]}
              </a>
            )
          })}
                </div>
                  <div className="text-sm leading-5">
                    <a href="/service-areas" className="font-medium text-red-600 hover:text-red-500 transition ease-in-out duration-150">
                      View Service Area Map &rarr;
                    </a>
                  </div>
                </div>
      </div>
    </div>
  </div>
  </nav>
</div>
)

export default Header
